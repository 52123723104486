import React, { useState } from "react";
import Calendar from "react-calendar";

const Widget = () => {
  const [value, onChange] = useState(new Date());

  return (
    <div>
      <h1>Hindu Calendar</h1>
      <Calendar onChange={onChange} value={value} />
    </div>
  );
};

export default Widget;
