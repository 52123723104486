import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`navbar-section`}>
      <div className="common-container">
        <div className="navbar__logo">
          <Link to="/">
            <img
              src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1707816916/Devdarshanam/Elegant_Initial_Jewelry_Logo-removebg-preview_q96ayv.png"
              alt=""
              className="navbar__logo--img"
            />
          </Link>
        </div>
        <div className="navbar-container">
          <div className="navbar-right">
            <div className="large-device-menu" style={{ color: "#fff" }}>
              <div
                className="large-device-menu__item"
                style={{ cursor: "pointer" }}
              >
                <Link to="/blogs/category/Ashram">ASHRAM</Link>
              </div>
              <div
                className="large-device-menu__item"
                style={{ cursor: "pointer" }}
              >
                <Link to="/blogs/category/Darshan">DARSHAN</Link>
              </div>
              <div
                className="large-device-menu__item"
                style={{ cursor: "pointer" }}
              >
                <Link to="/blogs/category/Deity">DEITY</Link>
              </div>
              <div
                className="large-device-menu__item"
                style={{ cursor: "pointer" }}
              >
                <Link to="/blogs/category/Hindu-Mentor">HINDU MENTOR</Link>
              </div>
              <div
                className="large-device-menu__item"
                style={{ cursor: "pointer" }}
              >
                <Link to="/blogs/category/Vedas">VEDAS</Link>
              </div>
            </div>

            <div className="small-device-menu">
              <div className="menu-bar">
                <svg
                  onClick={() => setIsOpen(true)}
                  className="small-device__sidebar-toggle"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="MenuIcon"
                  style={{ color: "#fff" }}
                >
                  <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                </svg>
              </div>
              <div className={`sidebar-wrapper ${isOpen ? "open" : ""}`}>
                <div className={`sidebar ${isOpen ? "" : "closeAnimation"} `}>
                  <div className="sidebar__header">
                    <div></div>
                    <div className="header__closeButton">
                      <button
                        onClick={() => setIsOpen(false)}
                        className="button"
                      >
                        <AiOutlineClose className="button-icon" />
                      </button>
                    </div>
                  </div>
                  <div className="sidebar__body">
                    <li className="body__menu">
                      <Link
                        to="/blogs/category/Ashram"
                        onClick={() => setIsOpen(false)}
                      >
                        ASHRAM
                      </Link>
                    </li>
                    <li className="body__menu">
                      <Link
                        to="/blogs/category/Darshan"
                        onClick={() => setIsOpen(false)}
                      >
                        DARSHAN
                      </Link>
                    </li>
                    <li className="body__menu">
                      <Link
                        to="/blogs/category/Deity"
                        onClick={() => setIsOpen(false)}
                      >
                        DEITY
                      </Link>
                    </li>
                    <li className="body__menu">
                      <Link
                        to="/blogs/category/Hindu-Mentor"
                        onClick={() => setIsOpen(false)}
                      >
                        HINDU MENTOR
                      </Link>
                    </li>
                    <li className="body__menu">
                      <Link
                        to="/blogs/category/Vedas"
                        onClick={() => setIsOpen(false)}
                      >
                        VEDAS
                      </Link>
                    </li>
                  </div>
                </div>
                <div
                  className="sidebar__backdrop"
                  onClick={() => setIsOpen(false)}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
