import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage/HomePage";
import BlogsPage from "../pages/Blogs/BlogsPage";
import BlogsWithCategory from "../pages/BlogsWithCategory/BlogsWithCategory";
import SingleBlog from "../pages/SingleBlog/SingleBlog";

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/blogs" element={<BlogsPage />} />
        <Route
          exact
          path="/blogs/category/:id"
          element={<BlogsWithCategory />}
        />
        <Route exact path="/blogs/:id" element={<SingleBlog />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
