export const blogsData = [
  {
    id: "01",
    title: "List of largest Hindu ashrams",
    category: "Ashram",
    date: "March 11, 2024",
    images: [
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Vishalakshmi_Mantap.jpg/210px-Vishalakshmi_Mantap.jpg",
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Aurobindo_ashram%2C_Pondicherry.jpg/210px-Aurobindo_ashram%2C_Pondicherry.jpg",
    ],
    paragraph:
      "The Art of Living International Center is the headquarter of the Art of Living Foundation. The center is located 21 km southwest of Bangalore on Kanakapura road, at the top of the Panchagiri Hills. It is connected by Road via Nice Ring Road or Banshankari - Kanakpura Road.",
    description: (
      <div>
        <div className="blog__image">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Vishalakshmi_Mantap.jpg/210px-Vishalakshmi_Mantap.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">The Art of Living International Center</h4>
        <p className="blog--paragraph">
          The Art of Living International Center is the headquarter of the Art
          of Living Foundation. The center is located 21 km southwest of
          Bangalore on Kanakapura road, at the top of the Panchagiri Hills. It
          is connected by Road via Nice Ring Road or Banshankari - Kanakpura
          Road.
        </p>

        <div className="blog__image">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Aurobindo_ashram%2C_Pondicherry.jpg/210px-Aurobindo_ashram%2C_Pondicherry.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">Sri Aurobindo Ashram</h4>
        <p className="blog--paragraph">
          The Sri Aurobindo Ashram is a spiritual community (ashram) located in
          Pondicherry, in the Indian territory of Puducherry. The ashram grew
          out of a small community of disciples who had gathered around Sri
          Aurobindo after he retired from politics and settled in Pondicherry in
          1910. On 24 November 1926, after a major spiritual realization, Sri
          Aurobindo withdrew from public view in order to continue his spiritual
          work. At this time he handed over the full responsibility for the
          inner and outer lives of the sadhaks (spiritual aspirants) and the
          ashram to his spiritual collaborator, "the Mother", earlier known as
          Mirra Alfassa. This date is therefore generally known as the
          founding-day of the ashram, though, as Sri Aurobindo himself wrote, it
          had “less been created than grown around him as its centre.”
        </p>
      </div>
    ),
  },
  {
    id: "04",
    title: "List of Hindu temples in India",
    category: "Darshan",
    date: "March 11, 2024",
    images: [
      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Tirumala_Venkateswara_temple_entrance_09062015.JPG/1280px-Tirumala_Venkateswara_temple_entrance_09062015.JPG",
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Kanakadurga_Temple_gopuram.jpg/1280px-Kanakadurga_Temple_gopuram.jpg",
    ],
    paragraph:
      "This pilgrimage centre is called 'Dwaraka Tirumala' after the great saint, 'Dwaraka' who located the self manifested idol of Lord 'Sri Venkateswara' after severe penance in a 'Valmikam' (ant hill). The devotees call Sri Venkateswara as Kali Yuga Vaikunta Vasa.",
    description: (
      <div>
        <div className="blog__image">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Tirumala_Venkateswara_temple_entrance_09062015.JPG/1280px-Tirumala_Venkateswara_temple_entrance_09062015.JPG"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">Venkateswara Temple, Dwaraka Tirumala</h4>
        <p className="blog--paragraph">
          This pilgrimage centre is called "Dwaraka Tirumala" after the great
          saint, "Dwaraka" who located the self manifested idol of Lord "Sri
          Venkateswara" after severe penance in a 'Valmikam' (ant hill). The
          devotees call Sri Venkateswara as Kali Yuga Vaikunta Vasa. This place
          is also called "Chinna Tirupati". As per Sastras North Indian rivers
          like Ganges and Yamuna are considered to be more and more holy as they
          go up to the origin and south Indian rivers like Krishna and Godavary
          are more and more holy as they go down the river to its mouth to the
          Sea. It is why there are numerous shrines and holy bathing ghats, at
          close intervals, on both sides of the great grand rivers Krishna and
          Godavary in their lower regions.
        </p>

        <div className="blog__image">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Kanakadurga_Temple_gopuram.jpg/1280px-Kanakadurga_Temple_gopuram.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">Kanaka Durga Temple</h4>
        <p className="blog--paragraph">
          Kanaka Durga Temple, also known as Sri Kanaka Durgamma Devasthanam, is
          a Hindu temple dedicated to Kanaka Durga. The deity in this temple is
          also popularly referred as Kanaka Durga. The temple is located in
          Vijayawada, Andhra Pradesh, India on the Indrakeeladri hill on the
          banks of Krishna River. Kaalika Purana, Durgaa Sapthashati and other
          Vedic literature have mentioned about Kanaka Durga on the
          Indrakeelaadri and have described the deity as Swayambhu,
          (self-manifested) in Triteeya Kalpa.
        </p>
      </div>
    ),
  },
  {
    id: "13",
    title: "Hindu texts",
    category: "Vedas",
    date: "March 11, 2024",
    images: [
      "https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Sanskrit_Manuscript_Wellcome_L0070805.jpg/1920px-Sanskrit_Manuscript_Wellcome_L0070805.jpg",
      "https://img.huffingtonpost.com/asset/5bb7e219210000d601c91828.jpeg?ops=scalefit_720_noupscale",
    ],
    paragraph:
      "Hindu texts or Hindu scriptures are manuscripts and voluminous historical literature which are related to any of the diverse traditions within Hinduism.  Some of the major Hindu texts include the Vedas, the Upanishads, and the Itihasa.",
    description: (
      <div>
        <div className="blog__image">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Sanskrit_Manuscript_Wellcome_L0070805.jpg/1920px-Sanskrit_Manuscript_Wellcome_L0070805.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <p className="blog--paragraph">
          Hindu texts or Hindu scriptures are manuscripts and voluminous
          historical literature which are related to any of the diverse
          traditions within Hinduism. Some of the major Hindu texts include the
          Vedas, the Upanishads, and the Itihasa. Scholars hesitate in defining
          the term "Hindu scriptures" given the diverse nature of Hinduism, but
          many list the Agamas as Hindu scriptures, and Dominic Goodall includes
          Bhagavata Purana and Yajnavalkya Smriti in the list of Hindu
          scriptures as well.
        </p>
        <p className="blog--paragraph">
          There are two historic classifications of Hindu texts: Shruti– that
          which is heard, and Smriti – that which is remembered. The Shruti
          texts refer to the body of most authoritative and ancient religious
          texts, believed to be eternal knowledge authored neither by human nor
          divine agent but transmitted by sages (rishis). These comprise the
          central canon of Hinduism. It includes the four Vedas including its
          four types of embedded texts – the Samhitas, the Brahmanas, the
          Aranyakas and the Upanishads. Of the Shrutis, the Upanishads alone are
          widely influential among Hindus, considered scriptures par excellence
          of Hinduism, and their central ideas have continued to influence its
          thoughts and traditions.
        </p>
      </div>
    ),
  },

  {
    id: "02",
    title: "Ashrams in India",
    category: "Ashram",
    date: "March 11, 2024",
    images: [
      "https://www.holidify.com/images/cmsuploads/compressed/Vishalakshi_Mantap_Night_20180317113948.jpg",
      "https://www.holidify.com/images/cmsuploads/compressed/Untitled_20191030150827.png",
    ],
    paragraph:
      "Located 21 kms southwest of Bangalore on Kanakpura Road atop Panchagiri Hills, Art of Living Centre is one of the topmost ashrams in India and is the headquarter of the spiritual, cultural organization Art of Living Foundation, established by Sri Sri Ravi Shankar.",
    description: (
      <div>
        <div className="blog__image">
          <img
            src="https://www.holidify.com/images/cmsuploads/compressed/Vishalakshi_Mantap_Night_20180317113948.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">
          Art of Living International Centre, Bangalore
        </h4>
        <p className="blog--paragraph">
          Located 21 kms southwest of Bangalore on Kanakpura Road atop
          Panchagiri Hills, Art of Living Centre is one of the topmost ashrams
          in India and is the headquarter of the spiritual, cultural
          organization Art of Living Foundation, established by Sri Sri Ravi
          Shankar. Constructed in 1986, the ashram focuses at creating a
          stress-free society through its multitudinous programmes which include
          yoga, meditation, Ayurveda treatments etc.
        </p>

        <div className="blog__image">
          <img
            src="https://www.holidify.com/images/cmsuploads/compressed/Untitled_20191030150827.png"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">Zorba The Buddha, Delhi</h4>
        <p className="blog--paragraph">
          Situated in a peaceful locality on Mehrauli - Gurgaon Road in Delhi,
          Zorba The Buddha is one of the topmost ashrams in India and a
          spiritual retreat centre that reeks of picturesque beauty, bounteous
          nature and immense peace. The architecture of the ashram is a
          combination of contemporary design and bamboo-mud-thatch style.
          Besides the regular meditative programmes, the ashram is blessed with
          utter tranquillity, gorgeous gardens, dainty ponds and stunning
          gardens that bloom with vibrant flowers.
        </p>
      </div>
    ),
  },
  {
    id: "03",
    title: "Best Yoga Ashrams in India",
    category: "Ashram",
    date: "March 11, 2024",
    images: [
      "https://icdn.isrgrajan.com/in/2020/07/Parmarth-Niketan-Rishikesh-Uttarakhand.jpg",
      "https://icdn.isrgrajan.com/in/2020/07/Yoga-Vidya-Mandir-Rishikesh-Uttarakhand.jpg",
    ],
    paragraph:
      "Parmarth Niketan, located in Rishikesh, Uttarakhand, is one of India’s largest and most revered yoga centers. Founded in 1942, Parmarth Niketan has been at the forefront of promoting the traditional Indian spiritual practices of yoga, meditation, and ayurveda for over 75 years.",
    description: (
      <div>
        <div className="blog__image">
          <img
            src="https://icdn.isrgrajan.com/in/2020/07/Parmarth-Niketan-Rishikesh-Uttarakhand.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">
          Parmarth Niketan, Rishikesh, Uttarakhand
        </h4>
        <p className="blog--paragraph">
          Parmarth Niketan, located in Rishikesh, Uttarakhand, is one of India’s
          largest and most revered yoga centers. Founded in 1942, Parmarth
          Niketan has been at the forefront of promoting the traditional Indian
          spiritual practices of yoga, meditation, and ayurveda for over 75
          years. Nestled on the banks of the holy Ganges river, Parmarth Niketan
          offers a peaceful and serene environment for individuals to deepen
          their spiritual connection and practice yoga. The ashram provides
          daily yoga classes, meditation sessions, and workshops to help
          individuals on their spiritual journey. In addition, the ashram also
          offers ayurvedic treatments and massages to help visitors rejuvenate
          their mind, body, and spirit.
        </p>

        <div className="blog__image">
          <img
            src="https://icdn.isrgrajan.com/in/2020/07/Yoga-Vidya-Mandir-Rishikesh-Uttarakhand.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">
          Yoga Vidya Mandir, Rishikesh, Uttarakhand
        </h4>
        <p className="blog--paragraph">
          Yoga Vidya Mandir in Rishikesh, Uttarakhand, is a premier yoga center
          dedicated to promoting the traditional practice of yoga. Established
          in 1977, Yoga Vidya Mandir has been providing a peaceful and nurturing
          environment for individuals to deepen their connection with their
          spiritual practice. Located on the banks of the holy Ganges river,
          Yoga Vidya Mandir offers daily yoga classes, meditation sessions, and
          workshops to help individuals on their spiritual journey. The ashram
          provides accommodation for visitors, ranging from simple
          dormitory-style rooms to private cottages, ensuring a comfortable stay
          for all.
        </p>
      </div>
    ),
  },

  {
    id: "05",
    title: "Famous Temples in India",
    category: "Darshan",
    date: "March 11, 2024",
    images: [
      "https://www.tourmyindia.com/blog//wp-content/uploads/2021/03/Popular-Temples-in-India.jpg",
      "https://www.tourmyindia.com/blog//wp-content/uploads/2021/01/Sun-Temple-Konark-Odisha.jpg",
      "https://www.tourmyindia.com/blog//wp-content/uploads/2021/03/Brihadeeswara-Temple.jpg",
    ],
    paragraph:
      "The Sun temple is situated in the small town of Konark situated in the Puri district of Odisha. This marvel of architecture is dedicated to Indian God of Sun, Surya. The temple has been built in the shape of his chariot which is drawn by seven horses.",
    description: (
      <div>
        <div className="blog__image">
          <img
            src="https://www.tourmyindia.com/blog//wp-content/uploads/2021/03/Popular-Temples-in-India.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">Sun Temple, Odisha</h4>
        <p className="blog--paragraph">
          The Sun temple is situated in the small town of Konark situated in the
          Puri district of Odisha. This marvel of architecture is dedicated to
          Indian God of Sun, Surya. The temple has been built in the shape of
          his chariot which is drawn by seven horses. The temple was built in
          the 13th century by a king called Narasimhadeva. There is an ancient
          legend attached to this temple, once Lord Krishna cursed one of his
          own sons with leprosy. To seek penance, his son, Samba worshiped Surya
          for twelve years.
        </p>

        <div className="blog__image">
          <img
            src="https://www.tourmyindia.com/blog//wp-content/uploads/2021/01/Sun-Temple-Konark-Odisha.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">Brihadeeswara Temple, Tamil Nadu</h4>
        <p className="blog--paragraph">
          Brihadeeswara Temple is one of the largest South-Indian temples
          situated in Thanjavur, Tamil Nadu. Also known as Peruvudaiyar Kovil
          and RajaRajeswaram, this 11th century temple was built by the Chola
          emperor Raja Raja Chola I. Cholas are known for their admiration for
          art. The opulence and artistic proficiency of Cholas is well reflected
          in the grand and magnificent architecture of the temple. Made entirely
          of granite stone, it was built as per the principles of Vaastu
          Shastra.
        </p>
      </div>
    ),
  },
  {
    id: "06",
    title: "Famous Temples in India You Must Visit",
    category: "Darshan",
    date: "March 11, 2024",
    images: [
      "https://travelogyindia.b-cdn.net/blog/wp-content/uploads/2019/04/Golden-Temple-Amritsar.jpg",
      "https://travelogyindia.b-cdn.net/blog/wp-content/uploads/2020/02/Kedarnath-Temple-Uttarakhand.jpg",
    ],
    paragraph:
      "The Sun temple is situated in the small town of Konark situated in the Puri district of Odisha. This marvel of architecture is dedicated to Indian God of Sun, Surya. The temple has been built in the shape of his chariot which is drawn by seven horses.",
    description: (
      <div>
        <p className="blog--paragraph">
          Mark Twain once said that India is the land of two million Gods.
          Indian civilization has grown for more than a couple of thousand years
          and this length gives out incredible diversity in customs, traditions,
          dialects, and others. This, in turn, gave out a wide spectrum of
          religions in the country.
        </p>
        <p className="blog--paragraph">
          Indian religion has a God for everything; starting from health to
          ultimate destruction. And, Indians made it a point to incorporate that
          custom into their lives. Here are the Top 30 Temples in India you
          ought to visit to enjoy that custom and tradition during your
          vacation.
        </p>

        <div className="blog__image">
          <img
            src="https://travelogyindia.b-cdn.net/blog/wp-content/uploads/2019/04/Golden-Temple-Amritsar.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">Golden Temple, Amritsar</h4>
        <p className="blog--paragraph">
          The Harmandir Sahib temple (Golden Temple) of the 16th century is open
          to all religions and creed. This is the iconic religious structure for
          Sikhs. The structure holds an old clock tower, main sanctum, museum,
          temple office, langar, and others. The langar (community kitchen)
          serves more than a hundred thousand people, every day.
        </p>

        <div className="blog__image">
          <img
            src="https://travelogyindia.b-cdn.net/blog/wp-content/uploads/2020/02/Kedarnath-Temple-Uttarakhand.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">
          Maa Vaishno Devi Temple and Cave, Jammu & Kashmir
        </h4>
        <p className="blog--paragraph">
          To visit this temple, pilgrims have to trek for 12 km from the base
          camp at Katra. This cave is located 5200 feet above the sea level, on
          the Trikuta Mountain. The main deity of this temple is Maa Vaishno
          Devi, who is represented as three heads made with rock, the Pindies.
          It is said that only those who are destined and decided by the deity
          can visit the temple. The temple is open throughout the year.
        </p>

        <h4 className="blog--title">Kedarnath Temple, Uttarakhand</h4>
        <p className="blog--paragraph">
          Kedarnath Temple is located in Garhwal region in the Himalayan ranges.
          This temple is considered as one of the best holy sites to worship
          Lord Shiva. The temple has a mythological link to Mahabharata, which
          states that Pandavas built this temple to get rid of the sins of
          taking lives during their war with Kauravs.
        </p>
      </div>
    ),
  },
  {
    id: "07",
    title: "List of Hindu deities",
    category: "Deity",
    date: "March 11, 2024",
    images: [
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Brahma%2C_Vishnu_and_Shiva_seated_on_lotuses_with_their_consorts%2C_ca1770.jpg/1920px-Brahma%2C_Vishnu_and_Shiva_seated_on_lotuses_with_their_consorts%2C_ca1770.jpg",
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/%E0%A6%9C%E0%A7%80._%E0%A6%A1%E0%A6%BF._%E0%A6%AC%E0%A7%8D%E0%A6%B2%E0%A6%95_%E0%A6%B8%E0%A6%B2%E0%A7%8D%E0%A6%9F%E0%A6%B2%E0%A7%87%E0%A6%95_%E0%A6%A6%E0%A7%82%E0%A6%B0%E0%A7%8D%E0%A6%97%E0%A6%BE_%E0%A6%AA%E0%A7%81%E0%A6%9C%E0%A7%8B_%E0%A7%A8%E0%A7%A6%E0%A7%A7%E0%A7%AE.jpg/1920px-%E0%A6%9C%E0%A7%80._%E0%A6%A1%E0%A6%BF._%E0%A6%AC%E0%A7%8D%E0%A6%B2%E0%A6%95_%E0%A6%B8%E0%A6%B2%E0%A7%8D%E0%A6%9F%E0%A6%B2%E0%A7%87%E0%A6%95_%E0%A6%A6%E0%A7%82%E0%A6%B0%E0%A7%8D%E0%A6%97%E0%A6%BE_%E0%A6%AA%E0%A7%81%E0%A6%9C%E0%A7%8B_%E0%A7%A8%E0%A7%A6%E0%A7%A7%E0%A7%AE.jpg",
    ],
    paragraph:
      "Hinduism is the largest religion in the Indian subcontinent, and the third largest religion in the world. Hinduism has been called the 'oldest religion in the world, and many practitioners refer to Hinduism as 'the eternal law' (Sanātana Dharma).",
    description: (
      <div>
        <p className="blog--paragraph">
          Hinduism is the largest religion in the Indian subcontinent, and the
          third largest religion in the world. Hinduism has been called the
          'oldest religion in the world, and many practitioners refer to
          Hinduism as 'the eternal law' (Sanātana Dharma). Within this faith,
          there are four major traditions or denominations, namely, Vaishnavism,
          Shaivism, Shaktism, and Smartism. There also exist a number of minor
          traditions, such as Ganapatism and Saurism.
        </p>

        <div className="blog__image">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Brahma%2C_Vishnu_and_Shiva_seated_on_lotuses_with_their_consorts%2C_ca1770.jpg/1920px-Brahma%2C_Vishnu_and_Shiva_seated_on_lotuses_with_their_consorts%2C_ca1770.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">Trimurti</h4>
        <p className="blog--paragraph">
          The Trimurti are the most prominent deities of contemporary Hinduism.
          This consists of Brahma- the Creator, Vishnu- the Preserver, and
          Shiva- the Destroyer. Their feminine counterparts are Saraswati- the
          wife of Brahma, Lakshmi- the wife of Vishnu, and Parvati (or Durga)-
          the wife of Shiva.
        </p>

        <div className="blog__image">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/%E0%A6%9C%E0%A7%80._%E0%A6%A1%E0%A6%BF._%E0%A6%AC%E0%A7%8D%E0%A6%B2%E0%A6%95_%E0%A6%B8%E0%A6%B2%E0%A7%8D%E0%A6%9F%E0%A6%B2%E0%A7%87%E0%A6%95_%E0%A6%A6%E0%A7%82%E0%A6%B0%E0%A7%8D%E0%A6%97%E0%A6%BE_%E0%A6%AA%E0%A7%81%E0%A6%9C%E0%A7%8B_%E0%A7%A8%E0%A7%A6%E0%A7%A7%E0%A7%AE.jpg/1920px-%E0%A6%9C%E0%A7%80._%E0%A6%A1%E0%A6%BF._%E0%A6%AC%E0%A7%8D%E0%A6%B2%E0%A6%95_%E0%A6%B8%E0%A6%B2%E0%A7%8D%E0%A6%9F%E0%A6%B2%E0%A7%87%E0%A6%95_%E0%A6%A6%E0%A7%82%E0%A6%B0%E0%A7%8D%E0%A6%97%E0%A6%BE_%E0%A6%AA%E0%A7%81%E0%A6%9C%E0%A7%8B_%E0%A7%A8%E0%A7%A6%E0%A7%A7%E0%A7%AE.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">Brahma</h4>
        <p className="blog--paragraph">
          Brahma is the god of creation, and the first of the Trimurti. His
          consort, as well as his shakti (divine energy), is Saraswati, the
          goddess of learning. He is identified with the Vedic creator god,
          Prajapati. His abode is at Satyaloka. The deity is said to have been
          born out of a lotus that grew out of the navel of Vishnu. He was given
          the four Vedas by Vishnu, and instructed to commence the act of
          creation. Brahma is not widely revered in contemporary Hinduism, as no
          major tradition emerged around his worship, as they did for Vishnu and
          Shiva.
        </p>
      </div>
    ),
  },
  {
    id: "08",
    title: "Hindu deities",
    category: "Deity",
    date: "March 11, 2024",
    images: [
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/%E0%A6%9C%E0%A7%80._%E0%A6%A1%E0%A6%BF._%E0%A6%AC%E0%A7%8D%E0%A6%B2%E0%A6%95_%E0%A6%B8%E0%A6%B2%E0%A7%8D%E0%A6%9F%E0%A6%B2%E0%A7%87%E0%A6%95_%E0%A6%A6%E0%A7%82%E0%A6%B0%E0%A7%8D%E0%A6%97%E0%A6%BE_%E0%A6%AA%E0%A7%81%E0%A6%9C%E0%A7%8B_%E0%A7%A8%E0%A7%A6%E0%A7%A7%E0%A7%AE.jpg/1920px-%E0%A6%9C%E0%A7%80._%E0%A6%A1%E0%A6%BF._%E0%A6%AC%E0%A7%8D%E0%A6%B2%E0%A6%95_%E0%A6%B8%E0%A6%B2%E0%A7%8D%E0%A6%9F%E0%A6%B2%E0%A7%87%E0%A6%95_%E0%A6%A6%E0%A7%82%E0%A6%B0%E0%A7%8D%E0%A6%97%E0%A6%BE_%E0%A6%AA%E0%A7%81%E0%A6%9C%E0%A7%8B_%E0%A7%A8%E0%A7%A6%E0%A7%A7%E0%A7%AE.jpg",
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Brahma%2C_Vishnu_and_Shiva_seated_on_lotuses_with_their_consorts%2C_ca1770.jpg/1920px-Brahma%2C_Vishnu_and_Shiva_seated_on_lotuses_with_their_consorts%2C_ca1770.jpg",
    ],
    paragraph:
      "Hindu deities are the gods and goddesses in Hinduism. Deities in Hinduism are as diverse as its traditions, and a Hindu can choose to be polytheistic, pantheistic, monotheistic, monistic, even agnostic, atheistic, or humanist. The terms and epithets for deities within the diverse traditions of Hinduism vary, and include Deva, Devi, Ishvara, Ishvari, Bhagavān and Bhagavati.",
    description: (
      <div>
        <div className="blog__image">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/%E0%A6%9C%E0%A7%80._%E0%A6%A1%E0%A6%BF._%E0%A6%AC%E0%A7%8D%E0%A6%B2%E0%A6%95_%E0%A6%B8%E0%A6%B2%E0%A7%8D%E0%A6%9F%E0%A6%B2%E0%A7%87%E0%A6%95_%E0%A6%A6%E0%A7%82%E0%A6%B0%E0%A7%8D%E0%A6%97%E0%A6%BE_%E0%A6%AA%E0%A7%81%E0%A6%9C%E0%A7%8B_%E0%A7%A8%E0%A7%A6%E0%A7%A7%E0%A7%AE.jpg/1920px-%E0%A6%9C%E0%A7%80._%E0%A6%A1%E0%A6%BF._%E0%A6%AC%E0%A7%8D%E0%A6%B2%E0%A6%95_%E0%A6%B8%E0%A6%B2%E0%A7%8D%E0%A6%9F%E0%A6%B2%E0%A7%87%E0%A6%95_%E0%A6%A6%E0%A7%82%E0%A6%B0%E0%A7%8D%E0%A6%97%E0%A6%BE_%E0%A6%AA%E0%A7%81%E0%A6%9C%E0%A7%8B_%E0%A7%A8%E0%A7%A6%E0%A7%A7%E0%A7%AE.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <p className="blog--paragraph">
          Hindu deities are the gods and goddesses in Hinduism. Deities in
          Hinduism are as diverse as its traditions, and a Hindu can choose to
          be polytheistic, pantheistic, monotheistic, monistic, even agnostic,
          atheistic, or humanist. The terms and epithets for deities within the
          diverse traditions of Hinduism vary, and include Deva, Devi, Ishvara,
          Ishvari, Bhagavān and Bhagavati.
        </p>
        <p className="blog--paragraph">
          Hindu deities are represented with various icons and anicons, in
          paintings and sculptures, called Murtis and Pratimas. Some Hindu
          traditions, such as ancient Charvakas, rejected all deities and
          concept of god or goddess, while 19th-century British colonial era
          movements such as the Arya Samaj and Brahmo Samaj rejected deities and
          adopted monotheistic concepts similar to Abrahamic religions. Hindu
          deities have been adopted in other religions such as Jainism, and in
          regions outside India, such as predominantly Buddhist Thailand and
          Japan, where they continue to be revered in regional temples or arts.
        </p>
      </div>
    ),
  },
  {
    id: "09",
    title: "List of Hindu deities",
    category: "Deity",
    date: "March 11, 2024",
    images: [
      "https://www.learnreligions.com/thmb/PfxooWWwutoBx7MkaV0xaXt223k=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/top-hindu-deities-1770309-99589e3bc1194d1a91305195565e9cca.png",
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/%E0%A6%9C%E0%A7%80._%E0%A6%A1%E0%A6%BF._%E0%A6%AC%E0%A7%8D%E0%A6%B2%E0%A6%95_%E0%A6%B8%E0%A6%B2%E0%A7%8D%E0%A6%9F%E0%A6%B2%E0%A7%87%E0%A6%95_%E0%A6%A6%E0%A7%82%E0%A6%B0%E0%A7%8D%E0%A6%97%E0%A6%BE_%E0%A6%AA%E0%A7%81%E0%A6%9C%E0%A7%8B_%E0%A7%A8%E0%A7%A6%E0%A7%A7%E0%A7%AE.jpg/1920px-%E0%A6%9C%E0%A7%80._%E0%A6%A1%E0%A6%BF._%E0%A6%AC%E0%A7%8D%E0%A6%B2%E0%A6%95_%E0%A6%B8%E0%A6%B2%E0%A7%8D%E0%A6%9F%E0%A6%B2%E0%A7%87%E0%A6%95_%E0%A6%A6%E0%A7%82%E0%A6%B0%E0%A7%8D%E0%A6%97%E0%A6%BE_%E0%A6%AA%E0%A7%81%E0%A6%9C%E0%A7%8B_%E0%A7%A8%E0%A7%A6%E0%A7%A7%E0%A7%AE.jpg",
    ],
    paragraph:
      "This is an alphabetically ordered list of Hindu deities. Many of the gods and goddesses on this list exist in multiple incarnations, some of which are treated in separate articles. Classical artistic depictions of certain deities are also covered separately in some cases.",
    description: (
      <div>
        <div className="blog__image">
          <img
            src="https://www.learnreligions.com/thmb/PfxooWWwutoBx7MkaV0xaXt223k=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/top-hindu-deities-1770309-99589e3bc1194d1a91305195565e9cca.png"
            className="blog__image--img"
            alt=""
          />
        </div>
        <p className="blog--paragraph">
          This is an alphabetically ordered list of Hindu deities. Many of the
          gods and goddesses on this list exist in multiple incarnations, some
          of which are treated in separate articles. Classical artistic
          depictions of certain deities are also covered separately in some
          cases.
        </p>

        <h4 className="blog--title">List:</h4>
        <li className="blog--paragraph">Aditi</li>
        <li className="blog--paragraph">Agni</li>
        <li className="blog--paragraph">Ardhanarishvara</li>
        <li className="blog--paragraph">Ayyappan</li>
        <li className="blog--paragraph">Balarama</li>
        <li className="blog--paragraph">Brahma</li>
      </div>
    ),
  },
  {
    id: "10",
    title: "Guru",
    category: "Hindu-Mentor",
    date: "March 11, 2024",
    images: [
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Guru_and_DiscipleI.jpg/1280px-Guru_and_DiscipleI.jpg",
      "https://upload.wikimedia.org/wikipedia/commons/9/91/Parmarth4.jpg",
    ],
    paragraph:
      "The Guru is an ancient and central figure in the traditions of Hinduism. The ultimate liberation, contentment, freedom in the form of moksha and inner perfection is considered achievable in the Hindu belief by two means: with the help of guru, and with evolution through the process of karma including rebirth in some schools of Hindu philosophy.",
    description: (
      <div>
        <div className="blog__image">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Guru_and_DiscipleI.jpg/1280px-Guru_and_DiscipleI.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <p className="blog--paragraph">
          Guru (/ˈɡuːruː/ Sanskrit: गुरु; IAST: guru; Pali: garu) is a Sanskrit
          term for a "mentor, guide, expert, or master" of certain knowledge or
          field. In pan-Indian traditions, a guru is more than a teacher:
          traditionally, the guru is a reverential figure to the disciple (or
          shisya in Sanskrit, literally seeker [of knowledge or truth]) or
          student, with the guru serving as a "counselor, who helps mold values,
          shares experiential knowledge as much as literal knowledge, an
          exemplar in life, an inspirational source and who helps in the
          spiritual evolution of a student". Whatever language it is written in,
          Judith Simmer-Brown says that a tantric spiritual text is often
          codified in an obscure twilight language so that it cannot be
          understood by anyone without the verbal explanation of a qualified
          teacher, the guru. A guru is also one's spiritual guide, who helps one
          to discover the same potentialities that the guru has already
          realized.
        </p>
        <p className="blog--paragraph">
          The oldest references to the concept of guru are found in the earliest
          Vedic texts of Hinduism. The guru, and gurukula – a school run by
          guru, were an established tradition in India by the 1st millennium
          BCE, and these helped compose and transmit the various Vedas, the
          Upanishads, texts of various schools of Hindu philosophy, and
          post-Vedic Shastras ranging from spiritual knowledge to various arts.
          By about mid 1st millennium CE, archaeological and epigraphical
          evidence suggest numerous larger institutions of gurus existed in
          India, some near Hindu temples, where guru-shishya tradition helped
          preserve, create and transmit various fields of knowledge. These gurus
          led broad ranges of studies including Hindu scriptures, Buddhist
          texts, grammar, philosophy, martial arts, music and painting.
        </p>
      </div>
    ),
  },
  {
    id: "11",
    title: "List of Hindu gurus and sants - Wikipedia",
    category: "Hindu-Mentor",
    date: "March 11, 2024",
    images: [
      "https://upload.wikimedia.org/wikipedia/commons/9/91/Parmarth4.jpg",
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Guru_and_DiscipleI.jpg/1280px-Guru_and_DiscipleI.jpg",
    ],
    paragraph:
      "A guru is defined as a 'teacher, spiritual guide, [or] godman,' by author David Smith. To obtain the title of guru, one must go through a standard initiation process referred to as diksha, in which they receive a mantra, or sacred Sanskrit phrase.",
    description: (
      <div>
        <div className="blog__image">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/9/91/Parmarth4.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <p className="blog--paragraph">
          A guru is defined as a "teacher, spiritual guide, godman," by author
          David Smith. To obtain the title of guru, one must go through a
          standard initiation process referred to as diksha, in which they
          receive a mantra, or sacred Sanskrit phrase.
        </p>

        <h4 className="blog--title">List:</h4>
        <li className="blog--paragraph">A.C. Bhaktivedanta Swami Prabhupada</li>
        <li className="blog--paragraph">Abhinavagupta</li>
        <li className="blog--paragraph">Adi Shankara</li>
        <li className="blog--paragraph">Acharya Prashant</li>
        <li className="blog--paragraph">Advaita Acharya</li>
        <li className="blog--paragraph">Agastyar</li>
      </div>
    ),
  },
  {
    id: "12",
    title: "List of Hindu gurus and sants",
    category: "Hindu-Mentor",
    date: "March 11, 2024",
    images: [
      "https://hinduismwayoflife.com/wp-content/uploads/2018/02/Shankaracharya1.jpg",
      "https://media.licdn.com/dms/image/D4D12AQHjIQVUC8YG8Q/article-cover_image-shrink_600_2000/0/1688383306701?e=2147483647&v=beta&t=z6xy5mkTaduIHvMmenUJ76J1U7WkF8wVT4kRN-Ft3lM",
    ],
    paragraph: "",
    description: (
      <div>
        <div className="blog__image">
          <img
            src="https://hinduismwayoflife.com/wp-content/uploads/2018/02/Shankaracharya1.jpg"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">List of Hindu Gurus and Sants:</h4>
        <li className="blog--paragraph">Devi Chitralekha Ji</li>
        <li className="blog--paragraph">Shri Devkinandan</li>
        <li className="blog--paragraph">Acharya Shivmuni Ji</li>
        <li className="blog--paragraph">Shri Swami</li>
        <li className="blog--paragraph">Krishna Priya Ji</li>
        <li className="blog--paragraph">Indresh Upadhyay Ji</li>
      </div>
    ),
  },

  {
    id: "14",
    title: "The Vedas World Civilization",
    category: "Vedas",
    date: "March 11, 2024",
    images: [
      "https://i0.wp.com/detechter.com/wp-content/uploads/2018/01/Rigveda-e1516291577811.jpg?fit=1000%2C523&ssl=1",
      "https://indiafacts.org/wp-content/uploads/2018/01/Sv%C4%81dhy%C4%81ya-Hinduism.jpg",
    ],
    paragraph:
      "The Indo-Aryan Vedas remain the oldest scriptures of Hinduism, which is considered one of the oldest religions in the world. Vedic ritualism, a composite of ancient Indo-Aryan and Harappan culture, contributed to the deities and traditions of Hinduism over time.",
    description: (
      <div>
        <p className="blog--paragraph">
          The Indo-Aryan Vedas remain the oldest scriptures of Hinduism, which
          is considered one of the oldest religions in the world. Vedic
          ritualism, a composite of ancient Indo-Aryan and Harappan culture,
          contributed to the deities and traditions of Hinduism over time. The
          Vedas are split into four major texts and contain hymns, mythological
          accounts, poems, prayers, and formulas considered sacred to the Vedic
          religion.
        </p>

        <div className="blog__image">
          <img
            src="https://i0.wp.com/detechter.com/wp-content/uploads/2018/01/Rigveda-e1516291577811.jpg?fit=1000%2C523&ssl=1"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">Structure Of The Vedas</h4>
        <p className="blog--paragraph">
          Vedas, meaning “knowledge,” were written in Vedic Sanskrit between
          1500 and 500 BCE in the northwestern region the Indian Subcontinent.
          The Vedas were transmitted orally during the course of numerous
          subsequent generations before finally being archived in written form.
          Not much is known about the authors of the Vedas, as the focus is
          placed on the ideas found in Vedic tradition rather than those who
          originated the ideas. The oldest of the texts is the Rig Veda, and
          while it is not possible to establish precise dates for each of the
          ancient texts, it is believed the collection was completed by the end
          of the 2nd millennium BCE (Before Common Era).
        </p>
      </div>
    ),
  },
  {
    id: "15",
    title: "The Vedas World History Encyclopedia",
    category: "Vedas",
    date: "March 11, 2024",
    images: [
      "https://i0.wp.com/mitiiks.org.in/wp-content/uploads/2022/03/Veda-1-Introduction-to-Vedas-.png?fit=1080%2C540&ssl=1",
      "https://i0.wp.com/detechter.com/wp-content/uploads/2018/01/Rigveda-e1516291577811.jpg?resize=662%2C346&ssl=1",
    ],
    paragraph:
      "The Aryan pantheon of gods is described in great detail in the Rig Veda. However, the religious practices and deities are not uniformly consistent in these sacred texts, probably because the Aryans themselves were not a homogenous group. While spreading through the Indian Subcontinent, it is probable that their initial religious beliefs and practices were shaped by the absorption of local religious traditions.",
    description: (
      <div>
        <p className="blog--paragraph">
          The Aryan pantheon of gods is described in great detail in the Rig
          Veda. However, the religious practices and deities are not uniformly
          consistent in these sacred texts, probably because the Aryans
          themselves were not a homogenous group. While spreading through the
          Indian Subcontinent, it is probable that their initial religious
          beliefs and practices were shaped by the absorption of local religious
          traditions.
        </p>
        <p className="blog--paragraph">
          According to the hymns of the Rig Veda, the most important deities
          were Agni, the god of Fire, intermediary between the gods and humans;
          Indra, the god of Heavens and War, protector of the Aryans against
          their enemies; Surya, the Sun god; Vayu, the god of Wind; and Prthivi,
          the goddess of Earth.
        </p>

        <div className="blog__image">
          <img
            src="https://i0.wp.com/mitiiks.org.in/wp-content/uploads/2022/03/Veda-1-Introduction-to-Vedas-.png?fit=1080%2C540&ssl=1"
            className="blog__image--img"
            alt=""
          />
        </div>
        <h4 className="blog--title">Vedas And Castes</h4>
        <p className="blog--paragraph">
          The Caste System, or groups based on birth or employment status, has
          been part of the social fabric of the Indian Subcontinent since
          ancient times. The castes are thought to have derived from a hymn
          found in the Vedas to the deity Purusha, who is believed to have been
          sacrificed by the other gods. Afterward Purusha’s mind became the
          Moon, his eyes became the Sun, his head the Sky, and his feet the
          Earth.
        </p>
        <p className="blog--paragraph">
          The passage describing the classes of people derived from the
          sacrifice of Purusha is the first indication of a caste system. The
          Brahmins, or priests, came from Purusha’s mouth; the Kshatriyas, or
          warrior rulers, came from Purusha’s arms; the Vaishyas, or commoners
          such as landowners and merchants, came from Purusha’s thighs; and the
          Shudras, or laborers and servants, came from Purusha’s feet.
        </p>
      </div>
    ),
  },
];
