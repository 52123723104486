import React, { useEffect, useState } from "react";
import Navbar from "../../containers/Shared/Navbar";
import { useLocation } from "react-router";
import { blogsData } from "../../utils/data";
import { Link } from "react-router-dom";

const BlogsWithCategory = () => {
  const [blogs, setBlogs] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    setBlogs(
      blogsData?.filter((blog) => blog?.category === pathname.split("/")[3])
    );
  }, [pathname]);

  return (
    <div>
      <Navbar />
      <div className="common-container">
        <div className="blogs" style={{ margin: "60px 0" }}>
          <div className="blogs-wrapper">
            {blogs?.map((data, index) => (
              <Link
                to={`/blogs/${data?.id}`}
                key={index}
                className="blogs__single"
              >
                <img
                  src={data?.images[0]}
                  className="blogs__single--img"
                  alt=""
                />
                <div className="blogs__content">
                  <h5 className="blogs__content--category">{data?.category}</h5>
                  <h3 className="blogs__content--heading">{data?.title}</h3>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsWithCategory;
