import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Hero = ({ items }) => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 2000,
    cssEase: "ease",
    dots: true,
  };

  return (
    <div className="hero-section">
      <div className="common-container">
        <div className="hero-container">
          <Slider {...settings} className="hero__body">
            {items?.map((item, index) => (
              <div key={index} className="hero__item">
                <img src={item} alt="" className="hero__item--img" />
                <div className="hero__item--overlay"></div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Hero;
