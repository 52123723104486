import React, { useEffect, useState } from "react";
import Navbar from "../../containers/Shared/Navbar";
import { useLocation } from "react-router";
import { blogsData } from "../../utils/data";
import Hero from "../../containers/HomePage/Hero";
import Widget from "../../containers/HomePage/Widget";

const SingleBlog = () => {
  const [blog, setBlog] = useState({});
  const { pathname } = useLocation();

  const items = [
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1709977791/Devdarshanam/cabf0f3e-06e4-406d-84d6-4c60d86fea98_ea3ggf.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1709979124/Devdarshanam/WhatsApp_Image_2024-03-09_at_3.58.41_PM_klipl1.jpg",
  ];

  useEffect(() => {
    setBlog(blogsData?.find((blog) => blog?.id === pathname.split("/")[2]));
  }, [pathname]);

  return (
    <div>
      <Navbar />
      <div style={{ marginTop: "40px" }}>
        <Hero items={items} />
      </div>

      <div className="container-wrapper">
        <div className="common-container">
          <div className="blog-widget-container">
            <div className="blogs">
              <div className="singleBlog">
                <h2 className="singleBlog__heading">{blog?.title}</h2>
                <p className="singleBlog__date">{blog?.date}</p>
                <div>{blog?.description}</div>
              </div>
            </div>
            <div className="widgets">
              <h1 className="section__heading">Widget</h1>
              <Widget />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBlog;
