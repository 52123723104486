import React from "react";
import { blogsData } from "../../utils/data";
import { Link } from "react-router-dom";

const AllBlogs = () => {
  return (
    <div>
      <div className="common-container">
        <div className="blogs" style={{ margin: "60px 0" }}>
          <div className="blogs-wrapper">
            {blogsData?.map((data, index) => (
              <Link
                to={`/blogs/${data?.id}`}
                key={index}
                className="blogs__single"
              >
                <img
                  src={data?.images[0]}
                  className="blogs__single--img"
                  alt=""
                />
                <div className="blogs__content">
                  <h5 className="blogs__content--category">{data?.category}</h5>
                  <h3 className="blogs__content--heading">{data?.title}</h3>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllBlogs;
