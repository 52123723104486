import React from "react";
import { blogsData } from "../../utils/data";
import Navbar from "../../containers/Shared/Navbar";
import { Link } from "react-router-dom";
import AllBlogs from "./AllBlogs";

const BlogsPage = () => {
  return (
    <div>
      <Navbar />
      <AllBlogs />
    </div>
  );
};

export default BlogsPage;
