import React, { useEffect, useState } from "react";
import { blogsData } from "../../utils/data";
import { Link } from "react-router-dom";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  // const blogs = [
  //   {
  //     imgUrl:
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDTOy43Kv_ltO4ousENvrh_uvjLfcikGmMN-42poShhBwvIgjRmyOeom7HExuYYEl3UbI&usqp=CAU",
  //     category: "ASHRAM",
  //     title: "Ashrams in Rishikesh",
  //     description:
  //       "An Ashram is a place for where one can do various spritual practices, meditate and do Yoga to evolve and grow spiritually. There are some daily routine of the Ashram which is to be followed in order to stay in Ashram.",
  //     date: "April 27, 2023",
  //     url: "https://www.euttaranchal.com/tourism/ashrams-in-rishikesh.php",
  //   },
  //   {
  //     imgUrl:
  //       "https://www.dailygoodmorningkashmir.com/wp-content/uploads/2023/02/SSB-Pattan-GMK-e1676562289531.jpeg",
  //     category: "DARSHAN",
  //     title:
  //       "SSB organises Bharat Darshan tours for Kashmiri students as part of Civil Action Programme",
  //     description:
  //       "On the direction of Ministry of Home Affairs, Govt. of India and Force Head Quarter Sashastra Seema Bal, 02 BN SSB, Pattan, sent a contingent of 30 Kashmiri youth of different areas from Kashmir Valley for educational-cum-motivational Bharat Darshan Tour to New Delhi and Jaipur.",
  //     date: "June 13, 2023",
  //     url: "https://www.dailygoodmorningkashmir.com/ssb-organises-bharat-darshan-tours-for-kashmiri-students-as-part-of-civil-action-programme/",
  //   },
  //   {
  //     imgUrl:
  //       "https://res.cloudinary.com/djfzcmvmr/image/upload/v1709964892/Devdarshanam/The-Vedas_c9flij.jpg",
  //     category: "VEDAS",
  //     title: "The Hindu Vedas: Charms, Myths, and Formulas for Enlightenment",
  //     description:
  //       "The Vedas are the sacred scriptures of Hinduism and are regarded as the oldest religious texts in the world. Thought to have been composed at least 3,500 years ago, the Vedas are a collection of hymns, magical incantations, dramatic mythological accounts, and sacred formulas for enlightenment.",
  //     date: "August 22, 2023",
  //     url: "https://www.ancient-origins.net/artifacts-ancient-writings/vedas-ancient-mystical-texts-offer-charms-incantations-mythological-021161",
  //   },
  // ];

  console.log(blogs[0]);

  useEffect(() => {
    setBlogs(blogsData.slice(0, 3));
  }, []);

  return (
    <div className="blogs-section">
      {blogs?.map((data, index) => (
        <Link
          to={`/blogs/${data?.id}`}
          key={index}
          className="blog"
          href={data?.url}
        >
          <div className="blog__image">
            <img
              src={data?.images[0]}
              alt={data?.title}
              className="blog__image--img"
            />
          </div>
          <div className="blog__content">
            <h5 className="blog__content--category">{data?.category}</h5>
            <h2 className="blog__content--title">{data?.title}</h2>
            <h5 className="blog__content--date">{data?.date}</h5>
            <p className="blog__content--description">{data?.paragraph}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Blogs;
