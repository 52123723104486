import React from "react";
import Navbar from "../../containers/Shared/Navbar";
import Hero from "../../containers/HomePage/Hero";
import Blogs from "../../containers/HomePage/Blogs";
import Widget from "../../containers/HomePage/Widget";
import ImportantLinks from "../../containers/HomePage/ImportantLinks";
import AllBlogs from "../Blogs/AllBlogs";

const HomePage = () => {
  const items = [
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1709977791/Devdarshanam/cabf0f3e-06e4-406d-84d6-4c60d86fea98_ea3ggf.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1709979124/Devdarshanam/WhatsApp_Image_2024-03-09_at_3.58.41_PM_klipl1.jpg",
  ];

  return (
    <div className="home-page">
      <Navbar />
      <div className="container-wrapper">
        <Hero items={items} />
      </div>
      <div className="container-wrapper">
        <div className="common-container">
          <div className="blog-widget-container">
            <div className="blogs">
              <h1 className="section__heading">Latest Blogs</h1>
              <Blogs />
            </div>
            <div className="widgets">
              <h1 className="section__heading">Widget</h1>
              <Widget />
            </div>
          </div>
        </div>
      </div>
      <div className="container-wrapper">
        <h1
          className="section__heading"
          style={{ textAlign: "center", color: "gold" }}
        >
          All Blogs
        </h1>
        <AllBlogs />
      </div>
      <div className="container-wrapper">
        <div className="common-container">
          <div className="donate-section">
            <button className="donate-now__btn">Donate Now</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
